import React, { forwardRef, useEffect, useState } from 'react';

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import Loader from "components/Loader/Loader";

import { Redirect } from 'react-router-dom';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Referidos(){
    const [color, setColor] = useState("");
    const [message, setMessage] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const [load, setLoad] = useState(false);

    const [Nombre, setNombre] = useState("");
    const [IdEstatus, setIdEstatus] = useState("1");
    const [Celular, setCelular] = useState("");

    const [estatus, setEstatus] = useState([]);

    const sendSnackBar = (pmessage, pcolor) => {
        setOpenSnackbar(true);
        setColor(pcolor);
        setMessage(pmessage);
    };

    const SnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenSnackbar(false);
    };

    const Login = async () => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify({UserName: process.env.REACT_APP_USER, Password: process.env.REACT_APP_PASS}),
                headers: {
                    "Content-Type": "application/json"
                }
            };

            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Login",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const InsertaReferidos = async (data, token) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            };
    
            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Referidos/InsertaReferidos",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const getUser = () => {
        return JSON.parse(localStorage.getItem("userPortafolios")).IdUsuario;
    };

    const ValidaDatos = () => {
        if(Nombre !== "" && Celular !== ""){
                return { Nombre, Celular, IdEstatus: parseInt(IdEstatus), Capturista: parseInt(getUser()) };
        } else {
            return { error: true }
        }
    }

    const EnviaDatos = () => {
        setLoad(true);
        
        let datos = ValidaDatos();
        
        if(datos.error === undefined){
            Login()
            .then(result => {
                if(result !== undefined){
                    InsertaReferidos(datos, result.token)
                    .then(result => {
                        if(result.success){
                            sendSnackBar("Registro exitoso", "success");
                            setTimeout(function(){ window.location.reload(false); }, 1000);
                        } else {
                            sendSnackBar("Error de conexión", "error");
                        }
        
                        setLoad(false);
                    }).catch(error => sendSnackBar("Error de conexión", "error"));
                } else {
                    sendSnackBar("Error de conexión", "error");
                    setLoad(false);
                }
            }).catch(error => sendSnackBar("Error de conexión", "error"));
        } else {
            sendSnackBar("Todos los valores son obligatorios", "error");
            setLoad(false);
        }
    };

    const GetIdUsuario = () => {
        return JSON.parse(localStorage.getItem('userPortafolios')).IdUsuario;
    };

    const ObtieneEstatus = async (token) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            };
    
            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Estatus/ObtieneEstatus",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };
    
    const UsuarioActivo = async (data, token) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            };

            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Usuario/UsuarioActivo",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }

        return dataResponse;
    };

    useEffect(() => {
        Login()
        .then(result => {
          let token = result.token;
    
          if(result !== undefined) {
            UsuarioActivo({ IdUsuario: GetIdUsuario() },token)
            .then(result => {
              if(result.activo === false){
                localStorage.removeItem("userPortafolios");
                return <Redirect to='/admin'/>;
              }
            });

            ObtieneEstatus(token)
            .then(result => {
                setEstatus(result.estatus);
            }).catch(error => sendSnackBar("Error de conexión", "error"));
          } else {
            sendSnackBar("Error de conexión", "error");
          }
        });
      }, []);

    return (
        <>
            <Card style={{ marginBottom: "30px" }}>
                <Grid container spacing={3} style={{ padding: "30px" }}>
                    <Grid item xs={12} sm={12} xl={12}>
                        <Typography variant="h6">
                            Datos personales
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField onChange={(event) => setNombre(event.target.value)} value={Nombre} label="Nombre" variant="outlined" style={{ width: "100%" }} inputProps={{ maxlength: 100 }} />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField onChange={(event) => setCelular(event.target.value)} value={Celular} label="Celular" variant="outlined" style={{ width: "100%" }} inputProps={{ maxlength: 10 }} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField
                            select
                            label="Estatus*"
                            value={IdEstatus} 
                            onChange={(event) => setIdEstatus(event.target.value)} 
                            style={{ width: "100%" }}
                        >
                            {estatus.map((option) => (
                                <MenuItem key={option.IdEstatus} value={option.IdEstatus}>
                                    {option.Nombre}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            </Card>
            <Grid container
                direction="row-reverse"
                justifyContent="flex-start"
                alignItems="center">
                <Grid item xs={12} sm={12} xl={3}>
                    <Button
                        color="info"
                        variant="contained"
                        onClick={EnviaDatos}
                        style={{ float: "right" }}
                        size="large"
                        >
                            Enviar
                    </Button>
                </Grid>
            </Grid>
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={openSnackbar} onClose={SnackbarClose} autoHideDuration={6000}>
                <Alert onClose={SnackbarClose} severity={color} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
            <Loader load={load} />
        </>
    );
}

export default Referidos;