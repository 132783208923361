import React, { useState, useEffect, forwardRef } from "react";
import MaterialTable from "material-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import CardMaterial from '@material-ui/core/Card';
import CardHeaderMaterial from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import ButtonMaterial from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Loader from "components/Loader/Loader";

const styles = (theme) => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  azul: {
    background: "rgb(46, 185, 204) !important"
  },
  root: {
    margin: 'auto',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 200,
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  rootSelect: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    color: "#999 !important",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: "1.42857 !important",
    letterSpacing: "unset !important"
  },
  rootB: {
    "&:before": {
      borderBottomColor: "lightgrey !important"
    },
    "&:after": {
      borderBottomColor: "lightgrey !important"
    }
  },
  formControl: {
    marginTop: theme.spacing(3.5),
    minWidth: "100%"
  },
});

const useStyles = makeStyles(styles);

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function UserProfile() {
  const classes = useStyles();
  const [load, setLoad] = useState(false);

  const [column] = useState([
    { title: "Rol", field: "Rol", type: "string" }
  ]);

  const [columnUsuarios] = useState([
    { title: "Usuario", field: "Usuario", type: "string" },
    { title: "Nombre", field: "Nombre", type: "string" },
    { title: "Apellido Paterno", field: "ApellidoPaterno", type: "string" },
    { title: "Apellido Materno", field: "ApellidoMaterno", type: "string" },
    { title: "Correo", field: "CorreoElectronico", type: "string" }
  ]);

  const [columnPuestos] = useState([
    { title: "Puesto", field: "Puesto", type: "string" }
  ]);

  const [columnSucursales] = useState([
    { title: "Sucursal", field: "NombreSucursal", type: "string" }
  ]);

  const [usuarios, setUsuarios] = useState([]);
  const [usuario, setUsuario] = useState('');
  const [usuarioId, setUsuarioId] = useState('');
  const [pass, setPass] = useState('');
  const [nombre, setNombre] = useState('');
  const [apellidoP, setApellidoP] = useState('');
  const [apellidoM, setApellidoM] = useState('');
  const [correo, setCorreo] = useState('');

  const [rolId, setRolId] = useState("");
  const [puestoId, setPuestoId] = useState("");
  const [puestos, setPuestos] = useState([]);

  const [sucursalId, setSucursalId] = useState("");
  const [sucursal, setSucursal] = useState([]);

  const [idRol, setIdRol] = useState("");
  const [roles, setRoles] = useState([]);
  const [nombreRol, setNombreRol] = useState("");

  const [vistas, setVistas] = useState([]);
  const [vistasRol, setVistasRol] = useState([]);

  const [modal, setModal] = useState(false);
  const [modalUsuarios, setModalUsuarios] = useState(false);
  const [modaltitle, setModalTitle] = useState("");
  const [eventType, setEventType] = useState("");

  const [color, setColor] = useState("");
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState("");

  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleChange = (event, child, type) => {
    try {
      switch (type) {
        case "rol":
          setRolId(child.props.value);
          break;
        case "puesto":
          setPuestoId(child.props.value);
          break;
        case "sucursal":
          setSucursalId(child.props.value);
          break;
        default:
          break;
      }
    } catch (error) {
      sendSnackBar("Error de conexión", "error");
    }
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const SnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const validaRol = () => {
    return nombreRol !== "" && right.length > 0;
  };

  const validaUsuario = () => {
    return usuario !== "" && pass !== "" && nombre !== "" && apellidoP !== "" && apellidoM !== "" && correo !== "" && rolId !== "" && puestoId !== "" && sucursalId !== "";
  };

  const handleClickOpen = (event, data, type) => {
    switch (type) {
      case 'add':
        setModalTitle("Insertar Rol");
        setIdRol("");
        setNombreRol("");
        setEventType('add');
        setLeft(vistas);
        setRight([]);
        setModal(true);
        break;
      case 'edit':
        setModalTitle("Editar Rol");
        setIdRol(data.IdRol);
        setNombreRol(data.Rol);
        setEventType('edit');
        setModal(true);

        Login()
        .then(result => {
          if(result !== undefined){              
            ObtieneVistasPorRol(result.token, data.IdRol)
              .then(result => {
                let idVistaArray = result.rolVistas.map(element => { return element.IdVista; });
                let opciones = vistas.filter(element => !idVistaArray.includes(element.IdVista));
                let elegido = vistas.filter(element => idVistaArray.includes(element.IdVista));

                setLeft(opciones);
                setRight(elegido);
                setVistasRol(result.rolVistas);
              });
          } else {
            sendSnackBar("Error de conexión", "error");
          }
        });
        break;
      case 'addUsuario':
        setModalTitle("Agregar Usuario");
        setEventType('add');
        setUsuarioId("");
        setUsuario("");
        setPass("");
        setNombre("");
        setApellidoP("");
        setApellidoM("");
        setCorreo("");
        setRolId("");
        setPuestoId("");
        setSucursalId("");
        setModalUsuarios(true);

        break;
      case 'editUsuario':
        setModalTitle("Editar Usuario");
        setEventType('edit');
        setUsuarioId(data.IdUsuario);
        setUsuario(data.Usuario);
        setPass("");
        setNombre(data.Nombre);
        setApellidoP(data.ApellidoPaterno);
        setApellidoM(data.ApellidoMaterno);
        setCorreo(data.CorreoElectronico);
        setRolId(data.IdRol);
        setPuestoId(data.IdPuesto);
        setSucursalId(data.IdSucursales);
        setModalUsuarios(true);

        break;
      default:
        break;
    }
  };

  const handleClose = () => {
    setModal(false);
    setModalUsuarios(false);
  };

  const InsertaRol = async (token) => {
    let dataResponse;

    try {
      let header = {
        method: "POST",
        body: JSON.stringify({ Rol: nombreRol, IdAplicacion: parseInt(process.env.REACT_APP_ID) }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        }
      };

      let response = await fetch(process.env.REACT_APP_URL_APICORE + "Roles/InsertaRol", header);
      dataResponse = await response.json();
    } catch (error) {
      sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const InsertaUsuario = async (token) => {
    let dataResponse;

    try {
      let header = {
        method: "POST",
        body: JSON.stringify({ Usuario: usuario, Clave: pass, Nombre: nombre, ApellidoPaterno: apellidoP, 
          ApellidoMaterno: apellidoM, CorreoElectronico: correo, IdPuesto: puestoId, IdRol: rolId, 
          IdSucursales: sucursalId, IdAplicacion: parseInt(process.env.REACT_APP_ID) }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        }
      };

      let response = await fetch(process.env.REACT_APP_URL_APICORE + "Usuario/InsertaUsuario", header);
      dataResponse = await response.json();
    } catch (error) {
      sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const ActualizaUsuario = async (token) => {
    let dataResponse;

    try {
      let header = {
        method: "POST",
        body: JSON.stringify({ IdUsuario: usuarioId, Usuario: usuario, Clave: pass, Nombre: nombre, 
          ApellidoPaterno: apellidoP, ApellidoMaterno: apellidoM, CorreoElectronico: correo, IdPuesto: puestoId, 
          IdRol: rolId, Activo: true, IdSucursales: sucursalId, IdAplicacion: parseInt(process.env.REACT_APP_ID) }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        }
      };

      let response = await fetch(process.env.REACT_APP_URL_APICORE + "Usuario/ActualizaUsuario", header);
      dataResponse = await response.json();
    } catch (error) {
      sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const Desbloquea = async (token) => {
    let dataResponse;

    try {
      let header = {
        method: "POST",
        body: JSON.stringify({ IdUsuario: usuarioId }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        }
      };

      let response = await fetch(process.env.REACT_APP_URL_APICORE + "Usuario/Desbloquea", header);
      dataResponse = await response.json();
    } catch (error) {
      sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const ActualizaRol = async (token) => {
    let dataResponse;

    try {
      let header = {
        method: "POST",
        body: JSON.stringify({IdRol: idRol, Rol: nombreRol, Activo: true}),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        }
      };

      let response = await fetch(process.env.REACT_APP_URL_APICORE + "Roles/ActualizaRol", header);
      dataResponse = await response.json();
    } catch (error) {
      sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const ObtienePuestos = async (token) => {
    let dataResponse;

    try {
      let header = {
        method: "POST",
        body: JSON.stringify({ IdPuesto: 0, IdAplicacion: parseInt(process.env.REACT_APP_ID) }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        }
      };

      let response = await fetch(process.env.REACT_APP_URL_APICORE + "Puestos/ObtienePuestos", header);
      dataResponse = await response.json();
    } catch (error) {
      sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const inputChange = (event) => {
    try {
      switch (event.target.id) {
        case "rol":
            setNombreRol(event.target.value);
          break;
        case "usuario":
            setUsuario(event.target.value);
          break;
        case "pass":
            setPass(event.target.value);
          break;
        case "nombre":
            setNombre(event.target.value);
          break;
        case "apellidoP":
            setApellidoP(event.target.value);
          break;
        case "apellidoM":
            setApellidoM(event.target.value);
          break;
        case "correo":
            setCorreo(event.target.value);
          break;
        default:
          break;
      }
    } catch (error) {
      sendSnackBar("Error de conexión", "error");
    }
  };

  const handleSubmit = (event, type) => {
    event.preventDefault();

    if(type === 'rol'){
      switch (eventType) {
        case "add":
          if(validaRol()){
            Login()
            .then(result => {
              let token = result.token;
  
              if(result !== undefined) {
                InsertaRol(token)
                .then(result => {
                  right.forEach(element => {
                    InsertaRolVista(token, result.IdRol, element.IdVista)
                    .then(result => {});
                  });

                  ObtieneRoles(token)
                  .then(result => {
                    setRoles(result.roles);
                  });
                });
              } else {
                sendSnackBar("Error de conexión", "error");
              }
            });

            setModal(false);
          } else {
            sendSnackBar("Error de conexión", "error");
          }
          break;
        case "edit":
          if(validaRol()){
            Login()
            .then(result => {
              let token = result.token;
  
              if(result !== undefined) {
                ActualizaRol(token)
                .then(result => {
                  ObtieneRoles(token)
                  .then(result => {
                    setRoles(result.roles);
                  });
                });
                let idRightArray = right.map(element => { return element.IdVista; });
                let idVistasRolArray = vistasRol.map(element => { return element.IdVista; });
  
                let elegidos = vistasRol.length === 0 ? right : right.filter(element => !idVistasRolArray.includes(element.IdVista));
                let eliminados = vistasRol.filter(element => !idRightArray.includes(element.IdVista));
  
                elegidos.map(element => {
                  InsertaRolVista(token, idRol, element.IdVista)
                  .then(result => {});
                });
  
                eliminados.map(element => {
                  EliminaRolVista(token, idRol, element.IdVista)
                  .then(result => {});
                });
              } else {
                sendSnackBar("Error de conexión", "error");
              }
            });

            setModal(false);
          } else {
            sendSnackBar("Error de conexión", "error");
          }
          break;
        default:
          console.error("Caso no contemplado");
          break;
      }
    } else {
      switch (eventType) {
        case "add":
          if(validaUsuario()){
            Login()
            .then(result => {
              let token = result.token;
  
              if(result !== undefined) {
                InsertaUsuario(token)
                .then(result => {
                  ObtieneUsuarios(token)
                  .then(result => {
                    setUsuarios(result.usuarios);
                  });
                });
              } else {
                sendSnackBar("Error de conexión", "error");
              }
            });  

            setModalUsuarios(false);
          } else {
            sendSnackBar("Todos los campos son obligatorios", "error");
          }          
          break;
        case "edit":
          if(validaUsuario()){
            Login()
            .then(result => {
              let token = result.token;
  
              if(result !== undefined) {
                ActualizaUsuario(token)
                .then(result => {
                  ObtieneUsuarios(token)
                  .then(result => {
                    setUsuarios(result.usuarios);
                  });
                });
              } else {
                sendSnackBar("Error de conexión", "error");
              }
            });

            setModalUsuarios(false);
          } else {
            sendSnackBar("Todos los campos son obligatorios", "error");
          }          
          break;
        default:
          console.error("Caso no contemplado");
          break;
      }
    }
    
  };

  const desbloquear = (event) => {
    event.preventDefault();
    setLoad(true);

    Login()
    .then(result => {
      let token = result.token;

      if(result !== undefined) {
        Desbloquea(token)
        .then(result => {
          if(result.success){
            sendSnackBar("Actualización exitosa", "success");
            setLoad(false);
          }
        }).catch(error => {
          sendSnackBar("Error de conexión", "error");
          setLoad(false);
        });      
      } else {
        sendSnackBar("Error de conexión", "error");
        setLoad(false);
      }
    }).catch(error => {
      sendSnackBar("Error de conexión", "error");
      setLoad(false);
    });
  };

  const Login = async () => {
    let dataResponse;
    try {
        let header = {
            method: "POST",
            body: JSON.stringify({UserName: process.env.REACT_APP_USER, Password: process.env.REACT_APP_PASS}),
            headers: {
                "Content-Type": "application/json"
            }
        };

        let response = await fetch(
            process.env.REACT_APP_URL_APICORE + "Login",
            header
        );

        dataResponse = await response.json();
    } catch (error) {
      sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const sendSnackBar = (pmessage, pcolor) => {
    setOpenSnackbar(true);
    setColor(pcolor);
    setMessage(pmessage);
  };

  const ObtieneRoles = async (token) => {
    let dataResponse;

    try {
      let header = {
        method: "POST",
        body: JSON.stringify({ IdRol: 0, IdAplicacion: parseInt(process.env.REACT_APP_ID) }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        }
      };

      let response = await fetch(process.env.REACT_APP_URL_APICORE + "Roles/ObtieneRoles", header);
      dataResponse = await response.json();
    } catch (error) {
      sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const ObtieneVistas = async (token) => {
    let dataResponse;

    try {
      let header = {
        method: "POST",
        body: JSON.stringify({ IdAplicacion: parseInt(process.env.REACT_APP_ID) }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        }
      };

      let response = await fetch(process.env.REACT_APP_URL_APICORE + "Vistas/ObtieneVistas", header);
      dataResponse = await response.json();
    } catch (error) {
      sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const ObtieneVistasPorRol = async (token, IdRol) => {
    let dataResponse;

    try {
      let header = {
        method: "POST",
        body: JSON.stringify({ IdRol }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        }
      };

      let response = await fetch(process.env.REACT_APP_URL_APICORE + "Vistas/ObtieneVistasPorRol", header);
      dataResponse = await response.json();
    } catch (error) {
      sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const ObtieneUsuarios = async (token) => {
    let dataResponse;

    try {
      let header = {
        method: "POST",
        body: JSON.stringify({ idUsuario: 0, IdAplicacion: parseInt(process.env.REACT_APP_ID) }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        }
      };

      let response = await fetch(process.env.REACT_APP_URL_APICORE + "Usuario/ObtieneUsuarios", header);
      dataResponse = await response.json();
    } catch (error) {
      sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const InsertaRolVista = async (token, IdRol, IdVista) => {
    let dataResponse;

    try {
      let header = {
        method: "POST",
        body: JSON.stringify({ IdRol, IdVista }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        }
      };

      let response = await fetch(process.env.REACT_APP_URL_APICORE + "Roles/InsertaRolVista", header);
      dataResponse = await response.json();
    } catch (error) {
      sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const EliminaRolVista = async (token, IdRol, IdVista) => {
    let dataResponse;

    try {
      let header = {
        method: "POST",
        body: JSON.stringify({ IdRol, IdVista }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        }
      };

      let response = await fetch(process.env.REACT_APP_URL_APICORE + "Roles/EliminaRolVista", header);
      dataResponse = await response.json();
    } catch (error) {
      sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const customList = (title, items) => (
    <CardMaterial>
      <CardHeaderMaterial
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'todos los elementos seleccionados' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} seleccionados`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value.IdVista}-label`;

          return (
            <ListItem key={value.IdVista} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.NombreVista}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </CardMaterial>
  );

  const InsertaPuesto = async (token, data) => {
    let dataResponse;

    try {
      let header = {
        method: "POST",
        body: JSON.stringify({ Puesto: data.Puesto, IdAplicacion: parseInt(process.env.REACT_APP_ID) }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        }
      };

      let response = await fetch(process.env.REACT_APP_URL_APICORE + "Puestos/InsertaPuesto", header);
      dataResponse = await response.json();
    } catch (error) {
      sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const ActualizaPuesto = async (token, data) => {
    let dataResponse;

    try {
      let header = {
        method: "POST",
        body: JSON.stringify({ IdPuesto: data.IdPuesto , Puesto: data.Puesto , Activo: true }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        }
      };

      let response = await fetch(process.env.REACT_APP_URL_APICORE + "Puestos/ActualizaPuesto", header);
      dataResponse = await response.json();
    } catch (error) {
      sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const InsertaSucursal = async (token, data) => {
    let dataResponse;

    try {
      let header = {
        method: "POST",
        body: JSON.stringify({ NombreSucursal: data.NombreSucursal, IdAplicacion: parseInt(process.env.REACT_APP_ID) }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        }
      };

      let response = await fetch(process.env.REACT_APP_URL_APICORE + "Sucursales/InsertaSucursal", header);
      dataResponse = await response.json();
    } catch (error) {
      sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const ActualizaSucursal = async (token, data) => {
    let dataResponse;

    try {
      let header = {
        method: "POST",
        body: JSON.stringify({ IdSucursales: data.IdSucursales , NombreSucursal: data.NombreSucursal }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        }
      };

      let response = await fetch(process.env.REACT_APP_URL_APICORE + "Sucursales/ActualizaSucursal", header);
      dataResponse = await response.json();
    } catch (error) {
      sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const ObtieneSucursales = async (token, data) => {
    let dataResponse;

    try {
      let header = {
        method: "POST",
        body: JSON.stringify({ IdAplicacion: parseInt(process.env.REACT_APP_ID) }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        }
      };

      let response = await fetch(process.env.REACT_APP_URL_APICORE + "Sucursales/ObtieneSucursales", header);
      dataResponse = await response.json();
    } catch (error) {
      sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  useEffect(() => {
    try {
      Login()
      .then(result => {
        if(result !== undefined) {
          ObtieneRoles(result.token)
          .then(result => {
            setRoles(result.roles);
          });

          ObtieneVistas(result.token)
          .then(result => {
            setVistas(result.vistas);
          });

          ObtieneUsuarios(result.token)
          .then(result => {
            setUsuarios(result.usuarios);
          });

          ObtienePuestos(result.token)
          .then(result => {
            setPuestos(result.puestos);
          });

          ObtieneSucursales(result.token)
          .then(result => {
            setSucursal(result.sucursales);
          });
        } else {
          sendSnackBar("Error de conexión", "error");
        }
      });
    } catch (error) {
      sendSnackBar("Error de conexión", "error");
    }
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="danger" className={classes.azul}>
              <h4 className={classes.cardTitleWhite}>Roles</h4>
            </CardHeader>
            <CardBody>
              <MaterialTable
                title="Roles"
                columns={column}
                data={roles}
                actions={[
                  {
                    icon: "add",
                    tooltip: "Agregar Rol",
                    isFreeAction: true,
                    onClick: (e) => {
                      handleClickOpen(e, null, "add");
                    }
                  },
                  {
                    icon: "edit",
                    tooltip: "Editar Rol",
                    onClick: (e, oldData) => {
                      handleClickOpen(e, oldData, "edit");
                    }
                  }
                ]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="danger" className={classes.azul}>
              <h4 className={classes.cardTitleWhite}>Puesto</h4>
            </CardHeader>
            <CardBody>
            <MaterialTable
              title="Puestos"
              columns={columnPuestos}
              data={puestos}
              editable={{
                onRowAdd: newData =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      Login()
                      .then(result => {
                        if(result !== undefined) {
                          let token = result.token;

                          InsertaPuesto(token, newData)
                          .then(result => {
                            ObtienePuestos(token)
                            .then(result => {
                              setPuestos(result.puestos);
                            });
                          });
                        } else {
                          sendSnackBar("Error de conexión", "error");
                        }
                      });
                      
                      resolve();
                    }, 1000);
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      Login()
                      .then(result => {
                        if(result !== undefined) {
                          let token = result.token;

                          ActualizaPuesto(token, newData)
                          .then(result => {
                            ObtienePuestos(token)
                            .then(result => {
                              setPuestos(result.puestos);
                            });
                          });
                        } else {
                          sendSnackBar("Error de conexión", "error");
                        }
                      });
                      
                      resolve();
                    }, 1000);
                  })
              }}
            />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="danger" className={classes.azul}>
              <h4 className={classes.cardTitleWhite}>Sucursales</h4>
            </CardHeader>
            <CardBody>
            <MaterialTable
              title="Sucursales"
              columns={columnSucursales}
              data={sucursal}
              editable={{
                onRowAdd: newData =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      Login()
                      .then(result => {
                        if(result !== undefined) {
                          let token = result.token;

                          InsertaSucursal(token, newData)
                          .then(result => {
                            ObtieneSucursales(token)
                            .then(result => {
                              setSucursal(result.sucursales);
                            });
                          });
                        } else {
                          sendSnackBar("Error de conexión", "error");
                        }
                      });
                      
                      resolve();
                    }, 1000);
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      Login()
                      .then(result => {
                        if(result !== undefined) {
                          let token = result.token;

                          ActualizaSucursal(token, newData)
                          .then(result => {
                            ObtieneSucursales(token)
                            .then(result => {
                              setSucursal(result.sucursales);
                            });
                          });
                        } else {
                          sendSnackBar("Error de conexión", "error");
                        }
                      });
                      
                      resolve();
                    }, 1000);
                  })
              }}
            />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="danger" className={classes.azul}>
              <h4 className={classes.cardTitleWhite}>Usuarios</h4>
            </CardHeader>
            <CardBody>
              <MaterialTable
                title="Usuarios"
                columns={columnUsuarios}
                data={usuarios}
                actions={[
                  {
                    icon: "add",
                    tooltip: "Agregar Usuario",
                    isFreeAction: true,
                    onClick: (e) => {
                      handleClickOpen(e, null, "addUsuario");
                    }
                  },
                  {
                    icon: "edit",
                    tooltip: "Editar Usuario",
                    onClick: (e, oldData) => {
                      handleClickOpen(e, oldData, "editUsuario");
                    }
                  }
                ]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Dialog
        open={modal}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle className={classes.formControl} id="form-dialog-title">
          {modaltitle}
        </DialogTitle>
        <DialogContent>
          <CustomInput
            required={true}
            labelText="Nombre Rol"
            onChange={e => {
              inputChange(e);
            }}
            id="rol"
            formControlProps={{
              fullWidth: true
            }}
            attributes={{
              value: nombreRol
            }}
            labelProps={{
              required: true
            }}
            inputProps={{
              maxLength: 50
            }}
          />
          <h4 style={{textAlign: "center"}}>Vistas</h4>
          <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
            <Grid item>{customList('Opciones', left)}</Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <ButtonMaterial
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </ButtonMaterial>
                <ButtonMaterial
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </ButtonMaterial>
              </Grid>
            </Grid>
            <Grid item>{customList('Elegido', right)}</Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="danger">
            Cancelar
          </Button>
          <Button
            onClick={e => {
              handleSubmit(e, 'rol');
            }}
            color="info"
            type="submit"
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={modalUsuarios}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle className={classes.formControl} id="form-dialog-title">
          {modaltitle}
        </DialogTitle>
        <DialogContent>
          <CustomInput
            required={true}
            labelText="Usuario"
            onChange={e => {
              inputChange(e);
            }}
            id="usuario"
            formControlProps={{
              fullWidth: true
            }}
            attributes={{
              value: usuario
            }}
            labelProps={{
              required: true
            }}
            inputProps={{
              maxLength: 255
            }}
          />
          
          <CustomInput
            required={true}
            labelText="Contraseña"
            onChange={e => {
              inputChange(e);
            }}
            id="pass"
            formControlProps={{
              fullWidth: true
            }}
            attributes={{
              value: pass
            }}
            labelProps={{
              required: true
            }}
            inputProps={{
              maxLength: 10
            }}
          />

          <CustomInput
            required={true}
            labelText="Nombre"
            onChange={e => {
              inputChange(e);
            }}
            id="nombre"
            formControlProps={{
              fullWidth: true
            }}
            attributes={{
              value: nombre
            }}
            labelProps={{
              required: true
            }}
            inputProps={{
              maxLength: 255
            }}
          />

          <CustomInput
            required={true}
            labelText="Apellido Paterno"
            onChange={e => {
              inputChange(e);
            }}
            id="apellidoP"
            formControlProps={{
              fullWidth: true
            }}
            attributes={{
              value: apellidoP
            }}
            labelProps={{
              required: true
            }}
            inputProps={{
              maxLength: 255
            }}
          />

          <CustomInput
            required={true}
            labelText="Apellido Materno"
            onChange={e => {
              inputChange(e);
            }}
            id="apellidoM"
            formControlProps={{
              fullWidth: true
            }}
            attributes={{
              value: apellidoM
            }}
            labelProps={{
              required: true
            }}
            inputProps={{
              maxLength: 255
            }}
          />

          <CustomInput
            required={true}
            labelText="Correo"
            onChange={e => {
              inputChange(e);
            }}
            id="correo"
            formControlProps={{
              fullWidth: true
            }}
            attributes={{
              value: correo
            }}
            labelProps={{
              required: true
            }}
            inputProps={{
              maxLength: 255
            }}
          />

          <FormControl className={classes.formControl}>
            <InputLabel
              required={true}
              className={classes.rootSelect}
              id="puesto-label"
            >
              Puesto
            </InputLabel>
            <Select
              className={classes.rootB}
              labelId="puesto-label"
              id="puesto"
              formControlProps={{
                fullWidth: true
              }}
              value={puestoId}
              onChange={(e, c) => {
                handleChange(e, c, "puesto");
              }}
            >
              {puestos.map(item => {
                return (
                  <MenuItem
                    key={item.IdPuesto}
                    value={item.IdPuesto}
                  >
                    {item.Puesto}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl className={classes.formControl}>
            <InputLabel
              required={true}
              className={classes.rootSelect}
              id="rol-label"
            >
              Rol
            </InputLabel>
            <Select
              className={classes.rootB}
              labelId="rol-label"
              id="rol"
              formControlProps={{
                fullWidth: true
              }}
              value={rolId}
              onChange={(e, c) => {
                handleChange(e, c, "rol");
              }}
            >
              {roles.map(item => {
                return (
                  <MenuItem
                    key={item.IdRol}
                    value={item.IdRol}
                  >
                    {item.Rol}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl className={classes.formControl}>
            <InputLabel
              required={true}
              className={classes.rootSelect}
              id="sucursales-label"
            >
              Sucursales
            </InputLabel>
            <Select
              className={classes.rootB}
              labelId="sucursales-label"
              id="sucursal"
              formControlProps={{
                fullWidth: true
              }}
              value={sucursalId}
              onChange={(e, c) => {
                handleChange(e, c, "sucursal");
              }}
            >
              {sucursal.map(item => {
                return (
                  <MenuItem
                    key={item.IdSucursales}
                    value={item.IdSucursales}
                  >
                    {item.NombreSucursal}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={desbloquear} color="warning" style={{ margin: "auto" }}>
            Desbloquear
          </Button>
          <Button onClick={handleClose} color="danger">
            Cancelar
          </Button>
          <Button
            onClick={e => {
              handleSubmit(e, 'usuario');
            }}
            color="info"
            type="submit"
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={openSnackbar} onClose={SnackbarClose} autoHideDuration={6000}>
        <Alert onClose={SnackbarClose} severity={color} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <Loader load={load} />
    </div>
  );
}