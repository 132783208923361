import React, { useState, useEffect } from "react";

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import "assets/css/loader.css";

export default function ReporteCaptura(props) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if(props.load) {
            handleClickOpen();
        } else {
            handleClose();
        }
    }, [props.load]);

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            onClick={handleClose}
            disableEscapeKeyDown
            >
            <CircularProgress className="loader" color="inherit" />
        </Backdrop>
    );
}