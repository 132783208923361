import React, { useState, useEffect, forwardRef } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import DateRange from "@mui/icons-material/DateRange";
import AccessTime from "@mui/icons-material/AccessTime";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Loader from "components/Loader/Loader";

import { dailySalesChart } from "variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

import moment from "moment";
import "moment/locale/es";

import { Redirect } from 'react-router-dom';

moment.locale("es");

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles(styles);

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tab-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tab-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function Dashboard() {
  const classes = useStyles();

  const [value, setValue] = useState(0);

  const [MensualesPrograma, setMensualesPrograma] = useState();
  const [MensualesAcelerador, setMensualesAcelerador] = useState();

  const [DiariasPrograma, setDiariasPrograma] = useState();
  const [DiariasAcelerador, setDiariasAcelerador] = useState();

  const [AprobadasDiasPrograma, setAprobadasDiasPrograma] = useState([]);
  const [AprobadasDiasAcelerador, setAprobadasDiasAcelerador] = useState([]);

  const [AprobadasOptionsPrograma, setAprobadasOptionsPrograma] = useState({...dailySalesChart.options});
  const [AprobadasOptionsAcelerador, setAprobadasOptionsAcelerador] = useState({...dailySalesChart.options});

  const [aprobadasNombreDias, setAprobadasNombreDias] = useState([]);

  const [color, setColor] = useState("");
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [load, setLoad] = useState(false);

  const [IngresoDiario, setIngresoDiario] = useState(0);
  const [IngresoMensual, setIngresoMensual] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const sendSnackBar = (pmessage, pcolor) => {
    setOpenSnackbar(true);
    setColor(pcolor);
    setMessage(pmessage);
  };

  const GetIdUsuario = () => {
    return JSON.parse(localStorage.getItem('userPortafolios')).IdUsuario;
  };

  const Login = async () => {
    let dataResponse;
    try {
        let header = {
            method: "POST",
            body: JSON.stringify({UserName: process.env.REACT_APP_USER, Password: process.env.REACT_APP_PASS}),
            headers: {
                "Content-Type": "application/json"
            }
        };

        let response = await fetch(
            process.env.REACT_APP_URL_APICORE + "Login",
            header
        );

        dataResponse = await response.json();
    } catch (error) {
        sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const GetIdSucursal = () => {
    return JSON.parse(localStorage.getItem('userPortafolios')).IdSucursales;
  };

  const ReporteVentas = async (data, token) => {
    let dataResponse;
    try {
        let header = {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            }
        };

        let response = await fetch(
            process.env.REACT_APP_URL_APICORE + "Reportes/ReporteVentas",
            header
        );

        dataResponse = await response.json();
    } catch (error) {
        sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const ObtieneVentas = async (data, token) => {
    let dataResponse;
    try {
        let header = {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            }
        };

        let response = await fetch(
            process.env.REACT_APP_URL_APICORE + "Reportes/ObtieneVentas",
            header
        );

        dataResponse = await response.json();
    } catch (error) {
        sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const ObtieneIngreso = async (data, token) => {
    let dataResponse;
    try {
        let header = {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            }
        };

        let response = await fetch(
            process.env.REACT_APP_URL_APICORE + "Reportes/ObtieneIngreso",
            header
        );

        dataResponse = await response.json();
    } catch (error) {
        sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const UsuarioActivo = async (data, token) => {
    let dataResponse;
    try {
        let header = {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            }
        };

        let response = await fetch(
            process.env.REACT_APP_URL_APICORE + "Usuario/UsuarioActivo",
            header
        );

        dataResponse = await response.json();
    } catch (error) {
        sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  useEffect(() => {
    try{
      setLoad(true);

      let hoy = moment(Date.now());
      hoy.set({h: 0, m: 0, s: 0});

      let selectFechaInicio = moment(Date.now()).startOf('month');
      selectFechaInicio.set({h: 0, m: 0, s: 0});

      let selectFechaInicioSemana = moment(Date.now());
      selectFechaInicioSemana.set({h: 0, m: 0, s: 0});
      selectFechaInicioSemana.add(-8, 'd');

      let selectFechaFin = moment(Date.now());
      selectFechaFin.set({h: 23, m: 59, s: 59});

      let mensual = {
        FechaInicio: selectFechaInicio.format("YYYY/MM/DD HH:mm:ss"),
        FechaFin: selectFechaFin.format("YYYY/MM/DD HH:mm:ss"),
        IdSucursales: GetIdSucursal()
      };

      let semana = {
        FechaInicio: selectFechaInicioSemana.format("YYYY/MM/DD HH:mm:ss"),
        FechaFin: selectFechaFin.format("YYYY/MM/DD HH:mm:ss"),
        IdSucursales: GetIdSucursal()
      };

      let diario = {
        FechaInicio: hoy.format("YYYY/MM/DD HH:mm:ss"),
        FechaFin: selectFechaFin.format("YYYY/MM/DD HH:mm:ss"),
        IdSucursales: GetIdSucursal()
      };

      Login()
      .then(result => {
          if (result !== undefined) {
              UsuarioActivo({ IdUsuario: GetIdUsuario() }, result.token)
              .then(result => {
                if(result.activo === false){
                  localStorage.removeItem("userPortafolios");
                  return <Redirect to='/admin'/>;
                }
              });

              ReporteVentas(semana, result.token)
              .then(result => {
                let capturas = result.ventas.map((element) => {
                  element.FechaCapturista = element.FechaCapturista === "" ? "" : moment(moment(element.FechaCapturista, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                  
                  return element;
                });

                let dia = 0;
                let nombreDias = [];
                let diasPrograma = [];
                let diasAcelerador = [];
                while (dia <= 7) {
                  let fecha = moment(Date.now());
                  fecha.set({h: 0, m: 0, s: 0});
                  fecha.add(-dia, 'd');
                  let nombreDia = fecha.format('dddd').substring(0,2).toUpperCase();

                  if(nombreDia !== 'DO') {
                    let registrosPrograma = 0;
                    let registrosAcelerador = 0;

                    capturas.forEach(item => {
                      if(moment(item.FechaCapturista, "DD-MM-YYYY").isSame(fecha.toDate(), 'day') && item.IdEstatus === 1){
                          registrosPrograma = registrosPrograma + 1;
                      }

                      if(moment(item.FechaCapturista, "DD-MM-YYYY").isSame(fecha.toDate(), 'day') && item.IdEstatus === 2){
                         registrosAcelerador = registrosAcelerador + 1;
                      }
                    });

                    diasPrograma.push(registrosPrograma);
                    diasAcelerador.push(registrosAcelerador);

                    nombreDias.push(nombreDia);
                  }

                  dia = dia + 1;
                }

                let capPrograma = {...AprobadasOptionsPrograma};
                capPrograma.high = Math.max(...diasPrograma) + 10;
                let capAcelerador = {...AprobadasOptionsAcelerador};
                capAcelerador.high = Math.max(...diasAcelerador) + 10;

                setAprobadasOptionsPrograma(capPrograma);
                setAprobadasDiasPrograma(diasPrograma.reverse());
                setAprobadasOptionsAcelerador(capAcelerador);
                setAprobadasDiasAcelerador(diasAcelerador.reverse());

                setAprobadasNombreDias(nombreDias.reverse());
                setLoad(false);           
              });

              //Ingreso Programa 0
              ObtieneIngreso(diario, result.token)
              .then(result => {
                setIngresoDiario(parseInt(result.ingreso.Promedio));
              });
              ObtieneIngreso(mensual, result.token)
              .then(result => {
                setIngresoMensual(parseInt(result.ingreso.Promedio));
              });

              //Ventas Programa 0
              diario["Tipo"] = 1;
              ObtieneVentas(diario, result.token)
              .then(result => {
                setDiariasPrograma(parseInt(result.ventas.Promedio));
              });
              mensual["Tipo"] = 1;
              ObtieneVentas(mensual, result.token)
              .then(result => {
                setMensualesPrograma(parseInt(result.ventas.Promedio));
              });

              //Ventas Acelerador
              diario["Tipo"] = 2;
              ObtieneVentas(diario, result.token)
              .then(result => {
                setDiariasAcelerador(parseInt(result.ventas.Promedio));
              });
              mensual["Tipo"] = 2;
              ObtieneVentas(mensual, result.token)
              .then(result => {
                setMensualesAcelerador(parseInt(result.ventas.Promedio));
              });
          } else {
              sendSnackBar("Error de conexión", "error");
              setLoad(false);
          }
      }).catch(error => {
        console.log(error);
        sendSnackBar("Error de conexión", "error");
      });
    } catch(error){
        sendSnackBar("Error de conexión", "error");
        setLoad(false);
    }
  }, []);

  return (
    <div>
      <Box sx={{ width: '100%', padding: "20px" }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="abs" centered>
            <Tab label="Programa 0" {...a11yProps(0)} />
            <Tab label="Acelerador" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <Card>
                <CardHeader stats icon>
                  <p className={classes.cardCategory}>Ingreso Diario</p>
                  <h3 className={classes.cardTitle}>{"$" + IngresoDiario.toLocaleString('es-MX')}</h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <DateRange />
                    Últimas 24 Horas
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <Card>
                <CardHeader stats icon>
                  <p className={classes.cardCategory}>Ingreso Mensual</p>
                  <h3 className={classes.cardTitle}>{"$" + IngresoMensual.toLocaleString('es-MX')}</h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <DateRange />
                    Último mes
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <Card>
                <CardHeader color="rose" stats icon>
                  <p className={classes.cardCategory}>Ventas diarias</p>
                  <h3 className={classes.cardTitle}>{DiariasPrograma}</h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <DateRange />
                    Últimas 24 Horas
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <Card>
                <CardHeader color="primary" stats icon>
                  <p className={classes.cardCategory}>Ventas mensuales</p>
                  <h3 className={classes.cardTitle}>{MensualesPrograma}</h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <DateRange />
                    Último mes
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Card chart>
                <CardHeader color="rose">
                  <ChartistGraph
                    className="ct-chart"
                    data={{
                      labels: aprobadasNombreDias,
                      series: [AprobadasDiasPrograma]
                    }}
                    type="Line"
                    options={AprobadasOptionsPrograma}
                    listener={dailySalesChart.animation}
                  />
                </CardHeader>
                <CardBody>
                  <h4 className={classes.cardTitle}></h4>
                  <p className={classes.cardCategory}></p>
                </CardBody>
                <CardFooter chart>
                  <div className={classes.stats}>
                    <AccessTime /> Última semana
                  </div>
                </CardFooter>
              </Card>
            </GridItem>     
          </GridContainer>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <Card>
                <CardHeader color="rose" stats icon>
                  <p className={classes.cardCategory}>Ventas diarias</p>
                  <h3 className={classes.cardTitle}>{DiariasAcelerador}</h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <DateRange />
                    Últimas 24 Horas
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <Card>
                <CardHeader color="primary" stats icon>
                  <p className={classes.cardCategory}>Ventas mensuales</p>
                  <h3 className={classes.cardTitle}>{MensualesAcelerador}</h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <DateRange />
                    Último mes
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Card chart>
                <CardHeader color="rose">
                  <ChartistGraph
                    className="ct-chart"
                    data={{
                      labels: aprobadasNombreDias,
                      series: [AprobadasDiasAcelerador]
                    }}
                    type="Line"
                    options={AprobadasOptionsAcelerador}
                    listener={dailySalesChart.animation}
                  />
                </CardHeader>
                <CardBody>
                  <h4 className={classes.cardTitle}></h4>
                  <p className={classes.cardCategory}></p>
                </CardBody>
                <CardFooter chart>
                  <div className={classes.stats}>
                    <AccessTime /> Última semana
                  </div>
                </CardFooter>
              </Card>
            </GridItem>     
          </GridContainer>
        </CustomTabPanel>
      </Box>
      <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={openSnackbar} onClose={handleClose} autoHideDuration={6000}>
            <Alert onClose={handleClose} severity={color} sx={{ width: '100%' }}>
                {message}
            </Alert>
      </Snackbar>
      <Loader load={load} />
    </div>
  );
}
