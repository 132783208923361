import Dashboard from "@mui/icons-material/Dashboard";
import Input from "@mui/icons-material/Input";
import People from "@mui/icons-material/People";
import ListAlt from "@mui/icons-material/ListAlt";
import TrendingUp from "@mui/icons-material/TrendingUp";
import InsertChartOutlined from "@mui/icons-material/InsertChartOutlined";
import Publish from "@mui/icons-material/Publish";
import AddTaskIcon from '@mui/icons-material/AddTask';
import AccountBox from '@mui/icons-material/AccountBox';
import Ballot from '@mui/icons-material/Ballot';

import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import LogoutPage from "views/LoginPage/LogoutPage.jsx";
import Captura from "views/Invex/Captura.jsx";
import ReporteCaptura from "views/Invex/ReporteCaptura";
import ReporteDiario from "views/Invex/ReporteDiario";
import ReporteCapturaInvex from "views/Invex/ReporteCapturaInvex";
import CargaMasiva from "views/Invex/CargaMasiva";
import Referidos from "views/Invex/Referidos";
import ReporteReferidos from "views/Invex/ReporteReferidos";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
    id: 1
  },
  {
    path: "/usuarios",
    name: "Usuarios",
    icon: People,
    component: UserProfile,
    layout: "/admin",
    id: 2
  },
  {
    path: "/captura",
    name: "Captura",
    icon: ListAlt,
    component: Captura,
    layout: "/admin",
    id: 3
  },
  {
    path: "/cargamasiva",
    name: "Carga Masiva",
    icon: Publish,
    component: CargaMasiva,
    layout: "/admin",
    id: 4
  },
  {
    path: "/referidos",
    name: "Referidos",
    icon: AccountBox,
    component: Referidos,
    layout: "/admin",
    id: 8
  },
  {
    path: "/reportecaptura",
    name: "Reporte Captura",
    icon: TrendingUp,
    component: ReporteCaptura,
    layout: "/admin",
    id: 5
  },
  {
    path: "/reportegeneral",
    name: "Reporte General",
    icon: InsertChartOutlined,
    component: ReporteCapturaInvex,
    layout: "/admin",
    id: 6
  },
  {
    path: "/reportediario",
    name: "Reporte Diario",
    icon: AddTaskIcon,
    component: ReporteDiario,
    layout: "/admin",
    id: 7
  },
  {
    path: "/reportereferidos",
    name: "Reporte Referidos",
    icon: Ballot,
    component: ReporteReferidos,
    layout: "/admin",
    id: 9
  },
  {
    path: "/logout",
    name: "Cerrar Sesión",
    icon: Input,
    component: LogoutPage,
    layout: "/admin",
    id: 0
  }
];

export default dashboardRoutes;